
import { CardTypes } from "@/store/modules/card/card.types";
import { Component, Ref, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const card = namespace("Card");

@Component({
  components: {
    CardList: () => import("@/components/card/CardList.vue"),
  },
})
export default class CardsView extends Vue {
  @card.Mutation(CardTypes.SET_ADD_CARD_DIALOG)
  public setAddCard!: (addCard: boolean) => void;

  @card.Action(CardTypes.LOAD_CARDS)
  public loadCards!: () => void;

  @card.State("loadingCard")
  public loadingCards!: boolean;

  public pageSizes = [10, 15, 20];

  public pageSize = 15;

  public query = "";

  @Ref("cardList") public cardListRef!: { queryCards(): void };

  refresh(): void {
    this.cardListRef.queryCards();
  }

  openAddCardDialog(): void {
    this.setAddCard(true);
  }
}
